import React from "react"
import Layout from "../components/Layout"
import Article from "../components/sections/Article"
import { graphql } from "gatsby"
import { Main } from "../components/sections/Content"
import { DivDefaultWrapper } from "../components/parts/General"
import { Title } from "../components/parts/General"
import styled from "@emotion/styled"
import paper from "../images/backgrounds/footerBackground.jpg"
import Seo from "../components/Seo"

// #region styling
const MainNews = styled(Main)`
  background-image: url(${paper});
`
// #endregion

const NewsPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => (
      <Article key={edge.node.id} post={edge.node} teaser={true} />
    ))
  return (
    <Layout>
      <MainNews>
        <DivDefaultWrapper>
          <Title>Latest Updates</Title>
        </DivDefaultWrapper>
        {Posts}
      </MainNews>
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 160)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`

export const Head = () => (
  <Seo title="Wildermyth OST - Latest News On The Soundtrack" />
)
